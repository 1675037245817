.social {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &__item {
    width: 100%;
    padding-right: 20px;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      width: 50%;
    }

    @media (min-width: 1280px) {
      width: calc(100% / 3);
    }
  }

  &__link {
    color: rgba(255, 255, 255, 1);
  }
}
