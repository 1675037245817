.menu {
  $this: &;

  padding: 4px 0 0 20px;
  order: 1;

  @media (min-width: 960px) {
    padding-top: 8px;
    padding-right: 20px;
    order: 0;
  }

  &__hamburger {
    opacity: 0;
    position: absolute;

    &:checked ~ #{$this}__hamburger-btn > span {
      transform: rotate(45deg);
    }

    &:checked ~ #{$this}__hamburger-btn > span::before {
      top: 0;
      transform: rotate(0);
    }

    &:checked ~ #{$this}__hamburger-btn > span::after {
      top: 0;
      transform: rotate(90deg);
    }

    &:checked ~ #{$this}__items {
      visibility: visible;
      left: 0;
      z-index: 1;
    }
  }


  &__hamburger-btn {
    display: flex;
    align-items: center;
    position: relative;

    width: 26px;
    height: 26px;

    cursor: pointer;
    z-index: 1;

    @media (min-width: 960px) {
      display: none;
    }
  }

  &__hamburger-btn > span,
  &__hamburger-btn > span::before,
  &__hamburger-btn > span::after {
    display: block;
    position: absolute;

    width: 100%;
    height: 2px;

    background-color: #616161;

    transition-duration: .25s;
  }

  &__hamburger-btn > span::before {
    content: '';
    top: -8px;
  }

  &__hamburger-btn > span::after {
    content: '';
    top: 8px;
  }

  .menu__items {
    display: block;
    position: fixed;
    visibility: hidden;
    top: 0;
    left: -100%;

    width: 300px;
    height: 100%;

    margin: 0;
    padding: 80px 0;

    list-style: none;

    background-color: #ECEFF1;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);

    transition-duration: .25s;

    @media (min-width: 960px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative;
      top: auto;
      left: auto;
      width: auto;
      visibility: visible;
      background-color: transparent;
      box-shadow: none;
    }
  }

  &__item {
    display: block;
    color: #333;

    text-decoration: none;

    transition-duration: .25s;

    padding: 0 30px;

    #{$this}__link {
      border-bottom: 2px solid transparent;

      &:hover {
        border-bottom: 2px solid #000;
      }
    }
  }

  &__link {
    display: flex;
    text-decoration: none;
    padding-bottom: 20px;
  }
}
