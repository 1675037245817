.industry {
  &__wrapper {
    padding: 70px 20px 80px;
  }

  & h2 {
    font-size: 16px;
    line-height: 1.75;

    @media (min-width: 768px) {
      font-size: 24px;
      padding-left: 20px;
    }

    @media (min-width: 1280px) {
      font-size: 32px;
    }
  }

  &__counter {
    font-size: 12px;
  }


  &__items {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0 25px;
    position: relative;

    @media (min-width: 768px) {
      width: 50%;
      padding: 20px 20px 15px;
    }

    @media (min-width: 960px) {
      width: 25%;
      margin-bottom: 15px;
    }

    &:after {
      @media (min-width: 960px) {
        position: absolute;
        content: "";
        left: 20px;
        bottom: -15px;
        opacity: 0;
        transform: translateX(-12px);
        transition: transform .3s,opacity .3s;

        background-image: url(../img/arrow.svg);
        width: 24px;
        height: 14px;
        background-position: 50%;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    &:hover {
      &:after {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  & h3 {
    font-size: 16px;
    line-height: 2;
    cursor: pointer;
    margin-bottom: 12px;
    margin-top: 0;

    @media (min-width: 768px) {
      font-size: 24px;
    }

    @media (min-width: 768px) {
      font-size: 28px;
    }

    &:hover {

    }
  }

  &__description {
    font-size: 16px;
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.6);

    @media (min-width: 768px) {
      font-size: 18px;
    }

    @media (min-width: 768px) {
      font-size: 24px;
    }
  }
}
