.footer {
  background-color: rgba(0, 0, 0, 1);

  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 70px 20px 80px;

    @media (min-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__items {
    margin: 0 0 20px 0;
    padding: 0;
    list-style: none;
    width: 100%;

    @media (min-width: 768px) {
      width: 50%;
      padding-left: 20px;

      &:last-of-type {
        padding-left: 10px;
      }
    }

    @media (min-width: 1280px) {
      width: 20%;
    }
  }

  &__social {
    order: -1;

    @media (min-width: 768px) {
      width: 100%;
    }

    @media (min-width: 1280px) {
      width: 60%;
      order: 0;
    }
  }

  &__item {
    margin-bottom: 16px;
  }

  &__link {
    color: rgba(255, 255, 255, 1);
  }
}
