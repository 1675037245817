.translate-menu {
  margin: 0 40px 0 auto;
  padding-top: 8px;

  display: none;

  @media (min-width: 960px) {
    display: block;
  }

  &__items {
    &:hover {
      &__items {
        display: block;
      }
    }
  }

  &__item {
    display: none;

    &:first-of-type {
      display: block;
    }
  }
}
