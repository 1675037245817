// Vendor
// ---------------------------------

@import "vendor/normalize";
// Swiper 7.4.1
@import "vendor/swiper";

// Global
// ---------------------------------

@import "variables";
@import "mixins";
@import "global/fonts";
@import "global/reboot";
@import "global/utils";
@import "global/container";

// Blocks
// ---------------------------------

@import "block/header";
@import "block/menu";
@import "block/translate-menu";
@import "block/main";
@import "block/promo";
@import "block/developer";
@import "block/portfolio";
@import "block/industry";
@import "block/partners";
@import "block/reviews";
@import "block/footer";
@import "block/social";

// ❗❗❗ запрещено использовать изображения в css, касается как jpg \ png, так и svg

// ❗ обязательно используйте mixin hover-focus для отключения ховеров на тач устройствах
/* @mixin hover-focus {
    @media (hover: hover) {
      &:hover:not(.focus-visible) {
        @content;
      }
    }

    &.focus-visible:focus {
      @content;
    }
  }
*/
/*  @include hover-focus {
      opacity: 0.8;
    }
*/
// но не используем для текстовых полей ( input, textarea )
// так же в сборке есть отдельный миксин для hover
/*@mixin hover {
    @media (hover: hover) {
      &:hover:not(.focus-visible) {
        @content;
      }
    }
  }
*/
// для focus
/*@mixin focus {
    &.focus-visible:focus {
      @content;
    }
  }
*/
// и для active
/*@mixin active {
    &.focus-visible:active {
      @content;
    }
  }
*/
// адаптив пишем внутри каждого класса (смотрим container.scss)

// для фикса проблем с vh на iOS в сборке подключен скрипт
// используя vh на проекте задавайте их также как в примере в utils.scss

// для любых transition обязательно указывайте transition-property
// transition: $trans-default ❌ ---> transition: color $trans-default ✅
