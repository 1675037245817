.promo {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);

  &__wrapper {
    max-width: 1440px;
    width: 100%;
    padding: 15% 20px 35%;
  }

  & h1 {
    font-size: 32px;
    line-height: 1.3;
    margin-bottom: 12px;

    @media (min-width: 768px) {
      font-size: 48px;
      margin-bottom: 18px;
    }

    @media (min-width: 1280px) {
      font-size: 64px;
      margin-bottom: 24px;
    }
  }

  &__description {
    font-size: 12px;
    line-height: 2;

    @media (min-width: 768px) {
      max-width: 75%;
    }

    @media (min-width: 1280px) {
      max-width: 50%;
      font-size: 16px;
    }
  }

  & h1,
  &__description {
    color: white;
  }
}
