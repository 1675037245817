.reviews {
  &__wrapper {
    padding: 70px 20px 80px;
  }

  & h2 {
    font-size: 16px;
    line-height: 1.75;

    @media (min-width: 768px) {
      font-size: 24px;
      padding-left: 20px;
    }

    @media (min-width: 1280px) {
      font-size: 32px;
    }
  }
}
