.header {
  padding-top: 20px;
  padding-bottom: 20px;

  @media (min-width: 960px) {
    padding-bottom: 0;
  }

  &__wrapper {
    display: flex;
    padding: 0 20px 0;
    justify-content: space-between;

    @media (min-width: 960px) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: normal;
    }
  }

  &__connect-with-us {
    display: flex;
    font-size: 16px;
    line-height: 1;
    text-decoration: none;
    color: #000;
    background-color: #fff;
    padding: 8px 16px;
    border-radius: 24px;
    white-space: nowrap;
    border: 1px solid #ccc;
    margin-left: auto;

    @media (min-width: 960px) {
      margin-left: 0;
    }
  }

  &__logo {
    padding-top: 8px;
  }
}
