.partners {
  background-color: rgba(0, 0, 0, 1);
  color: white;

  &__wrapper {
    padding: 50px 20px 80px;
  }

  & h2 {
    font-size: 16px;
    line-height: 1.75;

    @media (min-width: 768px) {
      font-size: 24px;
      padding-left: 20px;
    }

    @media (min-width: 1280px) {
      font-size: 32px;
      margin-bottom: 32px;
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 15px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      width: 50%;
      padding: 20px 20px 15px;
    }

    @media (min-width: 1280px) {
      width: 25%;
    }

    & img {
      width: 100%;
    }
  }
}
